
import React from 'react';
import {
  Form,
  FormField,
  Grid,
  Input,
  Segment
} from 'semantic-ui-react';
import { currencySymbols } from '../../utils/currencyUtils';
import { limitDigits } from '../../utils/projectPlanningUtils';
import SingleIconButton from './buttons/SingleIconButton';

const headerYearStyle = { backgroundColor: '#80ba27', color: 'white', padding: '0.5rem 0.5rem', alignItems: 'center', display: 'flex' }
const totalContainerStyle = { backgroundColor: '#557483', display: 'flex', flexDirection: 'row', height: '100%', color: 'white', gap: '0.5rem', padding: '0.5rem 0.5rem', alignItems: 'center', flexGrow: '1', }
const totalContainerStyleT2 = { backgroundColor: '#557483', display: 'flex', flexDirection: 'row', height: '100%', color: 'white', gap: '0.5rem', padding: '0.5rem 0.5rem', alignItems: 'center' }
const budgetContainerStyle = { backgroundColor: '#47b1a3', display: 'flex', flexDirection: 'row', color: 'white', gap: '0.5rem', padding: '0.5rem 0.5rem', flexGrow: '1', alignItems: 'center', marginBottom: '0rem' }
const buttonContainerStyle = { display: 'flex', alignItems: 'center', marginBottom: '0px', marginLeft: '1rem' }
const buttonContainerStyleRight = { display: 'flex', alignItems: 'center', marginBottom: '0px', marginLeft: 'auto' }
const noPadding = { paddingTop: '0', paddingBottom: '0' }
const noPaddingBottom = { paddingBottom: '0', paddingTop: '0.5rem' }
const segmentNoMargin = { width: 'calc(100vw + 15%)', whiteSpace: 'nowrap', marginBottom: '0', marginTop: '0', paddingLeft: '0', borderBottom: '2px solid black', borderTop: '0', borderLeft: '0', borderRight: '0', borderRadius: '0', alignItems: 'center', paddingBottom: '0' }
const headerContainerStyleLeft = { display: 'flex', flexDirection: 'row', height: '100%', gap: '0.5rem', padding: '0.5rem 0.5rem', backgroundColor: '#eef2f3', flexGrow: '1', alignItems: 'center' }
const leftComponentOfRow = { paddingTop: '0', paddingBottom: '1rem', backgroundColor: 'white', paddingLeft: '0.5rem' }
const rightComponentOfRow = {}
const labelStyle = { paddingLeft: '0.5rem' };

const rightDivPfeT0 = { display: 'flex', flexDirection: 'column', flexGrow: '1', padding: '0' }

//LEFT COMPONENT START
const PfeHeaderLeft = ({ year, values, currency }) => (
  <>
    <div style={headerYearStyle}>{year}</div>
    <div style={headerContainerStyleLeft}>
      <div>
        <label>Exchange Rate: </label>
        <span style={{ color: 'black' }}>{values.exchange_rate}</span>
      </div>
      {values.external === 0 &&
        <div>
          <label>Hourly Cost: </label>
          {/* * FIX bug 55. hourly cost ha cifre decimali in piu' */}
          <span style={{ color: 'black' }}>{values.hourly_cost.toFixed(2)} {currency}</span>
        </div>}
      <div>
        {/* // * Bugfix 67: Hourly Rate valorizzato nelle fixed price / monthly rate. Vedi anche FIX bug 38 */}
        <label>Hourly Rate: </label>
        <span style={{ color: 'black' }}>{Number(['LX', 'LY', 'LZ', 'NB', 'L0'].includes(values.sales_level) ? 0 : values.hourly_rate).toFixed(2)} {currency}</span>
      </div>
    </div>
  </>
)

const T0HeaderLeft = ({ year, values, currency }) => (
  <>
    <div style={headerYearStyle}>{year}</div>
    <div style={headerContainerStyleLeft}>
      <div>
        <label>Exchange Rate: </label>
        <span style={{ color: 'black' }}>{values.exchange_rate}</span>
      </div>
      {values.external === 0 &&
        <div>
          <label>Daily Cost: </label>
          <span style={{ color: 'black' }}>{values.daily_cost.toFixed(2)} {currency}</span>
        </div>}
      <div>
        <label>Daily Rate:</label>
        <span style={{ color: 'black' }}>{values.daily_rate.toFixed(2)} {currency}</span>
      </div>
    </div>
  </>
)

const T2HeaderLeft = ({ year, values, currency }) => (
  <>
    <div style={headerYearStyle}>{year}</div>
    <div style={headerContainerStyleLeft}>
      <div>
        <label>Exchange Rate:</label>
        <span style={{ color: 'black' }}>{values.exchange_rate}</span>
      </div>
      {values.external === 0 &&
        <div>
          <label>Daily Cost: </label>
          <span style={{ color: 'black' }}>{values.daily_cost.toFixed(2)} {currency}</span>
        </div>}
      <div>
        <label>Daily Rate:</label>
        <span style={{ color: 'black' }}>{values.daily_rate.toFixed(2)} {currency}</span>
      </div>
    </div>

  </>
)

//LEFT COMPONENT END

//-----

//RIGHT COMPONENT START

const PfeHeaderRight = ({ currency, totalTime: totalHours, totalCost, totalRevenues }) => (
  <>
    <div style={totalContainerStyle}>
      <div>
        <label>Tot. Hours: </label>
        <span style={{ color: 'white' }}>{totalHours} h</span>
      </div>
      <div>
        <label>Tot. Remaining Costs: </label>
        <span style={{ color: 'white' }}>{totalCost.toFixed(2)} {currency}</span>
      </div>
      <div>
        <label>Tot. Remaining Revenues: </label>
        <span style={{ color: 'white' }}>{totalRevenues.toFixed(2)} {currency}</span>
      </div>
    </div>
  </>
)


const T0HeaderRight = ({ totalTime: totalDays }) => (
  <>
    <div style={totalContainerStyle}>
      <div>
        <label>Tot. Days: </label>
        <span style={{ color: 'white' }}>{totalDays.toFixed(2)} d</span>
      </div>
    </div>
  </>
)

const T2HeaderRight = ({ totalTime: totalDays }) => (
  <>
    <div style={totalContainerStyleT2}>
      <div>
        <label>Tot. Days: </label>
        <span style={{ color: 'white' }}>{totalDays.toFixed(2)} d</span>
      </div>
    </div>
  </>
)

//RIGHT COMPONENT END

//---

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']


const ProfessionalFigure = (props) => {

  const { year, values, budgetValues, setValues, isReadOnly, pfeOrBudget, documentType, theOneRing } = props

  // console.log("budgetValues", budgetValues)

  const options = {
    ...props.options,
    internalLevels: values.company === undefined || props.options.internalLevels.length === 0 ? [] : props.options.internalLevels.find(x => x.company_id === values.company_id).internal_levels.map(x => ({
      key: x.id,
      value: x.code,
      // bugfix UAT 19: use code instead of name
      text: x.code,
      cost_hour: x.cost_hour,
      currency: x.currency,
    }))
      .sort((a, b) => a.key - b.key),
    areas: values.company === undefined || props.options.areas.length === 0 ? [] : props.options.areas.find(x => x.company === values.company_id).hierarchy_units.map(x => ({
      key: x.code,
      value: x.name,
      text: x.name
    })),
  }

  // * FIX bug 61 e 66. Aree vuote: in alcuni PFE già esistenti (quelli che arrivano da produzione) le aree presentano il campo area vuoto.
  if (values.area_code !== null && options.areas.find(x => x.key === values.area_code) === undefined) {
    options.areas = [...options.areas, {
      key: values.area_code,
      value: values.area,
      text: values.area
    }]
  }

  const onChangeCompany = (_event, data) => {
    const selectedCompany = options.companies.find(c => c.value === data.value);
    const exchangeRate = options.exchangeRates.find(x => x.currency_0 === selectedCompany.currency_code && x.currency_1 === pfeOrBudget.currency_code)
    setValues({
      company: selectedCompany.text,
      company_id: selectedCompany.key,
      company_code: selectedCompany.value,
      exchange_rate: exchangeRate.conversion,
      level: null,
      level_id: null,
      hourly_cost: 0,
      area: null,
      area_code: null
    })
  }

  const onChangeInternalLevel = (_event, data) => {
    const selectedInternalLevel = options.internalLevels.find(c => c.value === data.value);
    console.assert(selectedInternalLevel !== undefined)
    if (documentType === "pfe") {
      setValues({ level: selectedInternalLevel.value, level_id: selectedInternalLevel.key, hourly_cost: selectedInternalLevel.cost_hour * values.exchange_rate })
    } else {
      setValues({ level: selectedInternalLevel.value, level_id: selectedInternalLevel.key, daily_cost: selectedInternalLevel.cost_hour * 8 * values.exchange_rate })
    }
  }

  const onChangeArea = (_event, data) => {
    const selectedArea = options.areas.find(c => c.value === data.value);
    console.assert(selectedArea !== undefined)
    setValues({ area: selectedArea.value, area_code: selectedArea.key })
  }

  // * FIX bug 38. hourly rate = 0 per i sales level: 'LX', 'LY', 'LZ', 'NB', 'L0'. Il BE passa daily_rate = 1.
  const onChangeSalesLevel = (_event, data) => {
    const selectedSalesLevel = options.salesLevels.find(c => c.value === data.value);
    // console.log("onChangeSalesLevel ~ selectedSalesLevel:", selectedSalesLevel)
    console.assert(selectedSalesLevel !== undefined)

    // * FIX bug UAT 44. hourly rate e daily rate = 0 per i sales level: 'LX', 'LY', 'LZ', 'NB', 'L0' sempre (cioe' anche quando a BE e' salvato diverso da 1)
    // BUG FIX UAT 52: mandare hourly_rate reale invece che 0, perche' 0 spacca lo storico del DB
    // il rate reale viene salvato in real_hourly_x, che manderemo al BE
    const daily_rate = ['LX', 'LY', 'LZ', 'NB', 'L0'].includes(selectedSalesLevel.value) ? 0 : selectedSalesLevel.daily_rate
    if (documentType === "pfe") {
      setValues({ sales_level: selectedSalesLevel.value, hourly_rate: (daily_rate / 8) * values.exchange_rate, real_hourly_rate: (selectedSalesLevel.daily_rate / 8) * values.exchange_rate, });
    } else {
      setValues({ sales_level: selectedSalesLevel.value, daily_rate: daily_rate * values.exchange_rate, real_daily_rate: selectedSalesLevel.daily_rate * values.exchange_rate, });
    }
  }

  const onChangeHourOrDay = (monthIndex, data, maxDigitsBeforeDecimal = 13, decimal = 2) => {
    console.assert(typeof data.value === "string")
    if (props.documentType === "pfe") {
      setValues({
        hours: values.hours.map(item => {
          if (item.year === year && item.month === monthIndex + 1) {
            const hour = limitDigits(data.value, maxDigitsBeforeDecimal, decimal)
            return {
              ...item,
              hour
            };
          }
          return item
        })
      })
    } else {
      setValues({
        days: values.days.map(item => {
          if (item.year === year && item.month === monthIndex + 1) {
            const day = limitDigits(data.value)
            return {
              ...item,
              day
            };
          }
          return item
        })
      })
    }

  }

  const isDeleteDisabled = () => {
    if (isReadOnly) return true
    const comesFromDb = values.fromDB;
    if (comesFromDb) {
      //bugfix 45: se nei mesi chiusi non sono presenti ore allora il tasto delete è attivo.
      const sum = documentType === "pfe"
        ? values.hours.filter(x => theOneRing(x.year, x.month).isDisabled || theOneRing(x.year, x.month).isCompanyCutoffDisabled).reduce((a, b) => a + b.hour, 0)
        : values.days.filter(x => theOneRing(x.year, x.month).isDisabled || theOneRing(x.year, x.month).isCompanyCutoffDisabled).reduce((a, b) => a + b.day, 0)
      return sum !== 0;
    }
    return false;
  }

  const currency = currencySymbols[pfeOrBudget.currency_code]
  const totalTime = documentType === "pfe" ? values.hours.reduce((a, b) => a + b.hour, 0) : values.days.reduce((a, b) => a + b.day, 0)

  const totalCost = documentType === "pfe" ?
    values.hours
      .filter(x => theOneRing(x.year, x.month).isEnabled)
      .map(x => x.hour * values.hourly_cost)
      .reduce((a, b) => a + b, 0)
    : values.days
      .filter(x => theOneRing(x.year, x.month).isEnabled)
      .map(x => x.day * values.daily_cost)
      .reduce((a, b) => a + b, 0)

  // * Bugfix 67: Hourly Rate valorizzato nelle fixed price / monthly rate. Vedi anche FIX bug 38
  // const result = ['LX', 'LY', 'LZ', 'NB', 'L0'].includes(values.sales_level) ? 0 : values.hourly_rate;
  const totalRevenues = documentType === "pfe" ?
    values.hours
      .filter(x => theOneRing(x.year, x.month).isEnabled)
      .map(x => x.hour * values.hourly_rate)
      .reduce((a, b) => a + b, 0)
    : values.days
      .filter(x => theOneRing(x.year, x.month).isEnabled)
      .map(x => x.day * values.daily_rate)
      .reduce((a, b) => a + b, 0)
  // console.log('totalRevenues values: ', values)


  const renderListInput = (isNotPfe, index, dayObject, hourObject, monthNames, isReadOnly, onChangeHourOrDay) => {
    const commonProps = {
      key: index,
      className: isNotPfe
        ? (
          theOneRing(dayObject.year, dayObject.month).isCutoffDisabled ||
            theOneRing(dayObject.year, dayObject.month).isCompanyCutoffDisabled ||
            theOneRing(dayObject.year, dayObject.month).isUpdateActualDisabled
            ? "supercutoff-input"
            : theOneRing(dayObject.year, dayObject.month).isDisabled
              ? "disabled-input"
              : ""
        )
        : (
          theOneRing(hourObject.year, hourObject.month).isCutoffDisabled ||
            theOneRing(hourObject.year, hourObject.month).isCompanyCutoffDisabled
            ? "supercutoff-input"
            : theOneRing(hourObject.year, hourObject.month).isDisabled
              ? "disabled-input"
              : ""
        ),
      style: isNotPfe ? { flex: 1 } : { width: "100%" }
    };

    const inputProps = {
      label: monthNames[index],
      fluid: true,
      type: "number",
      value: isNotPfe
        ? Math.round(dayObject.day * 100) / 100
        : hourObject.hour,
      onChange: (_event, data) => {
        _event.persist();
        if (isNotPfe) {
          onChangeHourOrDay(index, data);
        } else {
          onChangeHourOrDay(index, data, 13, 0);
        }
      },
      disabled: isNotPfe
        ? (
          isReadOnly ||
          theOneRing(dayObject.year, dayObject.month).isDisabled ||
          theOneRing(dayObject.year, dayObject.month).isCompanyCutoffDisabled ||
          theOneRing(dayObject.year, dayObject.month).isUpdateActualDisabled
        )
        : (
          isReadOnly ||
          theOneRing(hourObject.year, hourObject.month).isDisabled ||
          theOneRing(hourObject.year, hourObject.month).isCompanyCutoffDisabled
        ),
      min: 0,
      step: isNotPfe ? 0.01 : 1,
      className: "hoursOrDaysInput",
      onFocus: (e) => {
        if (e.target.value === '0') e.target.value = '';
      },
      onBlur: (e) => {
        if (e.target.value === '') e.target.value = '0';
      }
    };

    return (
      <div {...commonProps}>
        <Form.Input {...inputProps} />
      </div>
    );
  };




  return (
    <Segment style={segmentNoMargin}>
      <Form >
        <Grid padded >
          <Grid.Row style={noPaddingBottom}>
            {/* Prima colonna con due righe */}
            <Grid.Column width={values.external === 1 ? 6 : 5} style={leftComponentOfRow}>
              <Grid>
                <Grid.Row style={noPadding}>
                  <Grid.Column style={{ display: 'inline-flex', paddingRight: '0' }}>
                    {documentType === "pfe" &&
                      <PfeHeaderLeft
                        year={year}
                        values={values}
                        currency={currency}
                        totalTime={totalTime}
                        totalCost={totalCost}
                        totalRevenues={totalRevenues}
                        isDeleteDisabled={isDeleteDisabled}
                        onDeleteProfessionalFigure={props.onDeleteProfessionalFigure}
                      />
                    }
                    {documentType === "t0" &&
                      <T0HeaderLeft
                        year={year}
                        values={values}
                        currency={currency}
                        totalTime={totalTime}
                        isDeleteDisabled={isDeleteDisabled}
                        onDeleteProfessionalFigure={props.onDeleteProfessionalFigure}
                      />
                    }
                    {documentType === "t2" && (
                      <>
                        <T2HeaderLeft
                          year={year}
                          values={values}
                          budgetValues={budgetValues}
                          currency={currency}
                          totalTime={totalTime}
                          isDeleteDisabled={isDeleteDisabled}
                          onDeleteProfessionalFigure={props.onDeleteProfessionalFigure}
                        />
                        <T2HeaderRight totalTime={totalTime} />
                      </>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={noPadding}>
                  <Grid.Column style={{ display: 'flex', flexWrap: 'wrap', paddingRight: '0' }}>
                    <Form.Dropdown
                      name="company"
                      label="Company"
                      placeholder='-'
                      options={options.companies}
                      loading={false}
                      onChange={onChangeCompany}
                      value={values.company_code}
                      disabled={values.fromDB || isReadOnly}
                      search
                      button
                      basic
                      required
                      className="professionalFigureDropdown"
                    />
                    {values.external === 0 &&
                      <Form.Dropdown
                        name="internal level"
                        label="Internal Level"
                        placeholder='-'
                        options={options.internalLevels}
                        loading={false}
                        onChange={onChangeInternalLevel}
                        value={values.level}
                        disabled={values.fromDB || values.company_id === null || isReadOnly}
                        search
                        button
                        basic
                        required
                        className="professionalFigureDropdown"
                      />}
                    <Form.Dropdown
                      name="area"
                      label="Area"
                      placeholder='-'
                      options={options.areas}
                      loading={false}
                      onChange={onChangeArea}
                      value={values.area}
                      disabled={values.fromDB || values.level === "" || values.company_id === null || isReadOnly}
                      search
                      button
                      basic
                      required
                      className="professionalFigureDropdown"
                    />

                    <Form.Dropdown
                      name="sales_level"
                      label="Sales Level"
                      placeholder='-'
                      options={options.salesLevels}
                      loading={false}
                      onChange={onChangeSalesLevel}
                      value={values.sales_level}
                      disabled={values.fromDB || values.company_id === null || isReadOnly}
                      search
                      button
                      basic
                      required
                      className="professionalFigureDropdown"
                    />
                    {values.external === 1 &&
                      <FormField style={{ flex: '1' }}>
                        <label aria-disabled={values.fromDB}>{props.documentType === "pfe" ? "Hourly Cost" : "Daily Cost"}</label>
                        <Input
                          type="number"
                          label={currency}
                          labelPosition='right'
                          value={props.documentType === "pfe" ? values.hourly_cost : values.daily_cost}
                          min={0}
                          step={1}
                          onChange={(_event, data) => {
                            const parsedValue = limitDigits(data.value);
                            setValues(props.documentType === "pfe" ? { hourly_cost: parsedValue } : { daily_cost: parsedValue })
                          }}
                          disabled={isReadOnly || (values.fromDB && props.documentType !== "pfe")}
                          onKeyDown={(event) => { if (event.key === '-') { event.preventDefault() } }}
                          onFocus={(e) => { if (e.target.value === '0') e.target.value = ''; }}
                          onBlur={(e) => { if (e.target.value === '') e.target.value = '0'; }}
                          className="professionalFigureDropdown"
                        />
                      </FormField>
                    }

                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>


            {/* Seconda colonna con due righe */}
            <Grid.Column width={values.external === 1 ? 10 : 11} style={rightComponentOfRow}>
              <Grid style={documentType === "t2" ? { display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' } : {}}>

                {
                  documentType === "pfe" && (
                    <>
                      <div style={rightDivPfeT0}>
                        <Grid.Row style={noPadding}>
                          <Grid.Column style={{ paddingLeft: '0' }}>
                            <PfeHeaderRight
                              currency={currency}
                              totalTime={totalTime}
                              totalCost={totalCost}
                              totalRevenues={totalRevenues}
                            />

                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={noPadding}>
                          <Grid.Column style={{ display: 'flex', paddingLeft: '0' }}>
                            {values.hours.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((hourObject, index) => (
                              renderListInput(documentType !== "pfe", index, null, hourObject, monthNames, isReadOnly, onChangeHourOrDay)
                            ))
                            }
                          </Grid.Column>
                        </Grid.Row>
                      </div>
                    </>
                  )
                }

                {
                  documentType === "t0" && (
                    <>
                      <div style={rightDivPfeT0}>
                        <Grid.Row style={noPadding}>
                          <Grid.Column style={{ paddingLeft: '0' }}>
                            <T0HeaderRight
                              totalTime={totalTime}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={noPadding}>
                          <Grid.Column style={{ display: 'flex', paddingLeft: '0' }}>
                            {values.days.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((dayObject, index) => (
                              renderListInput(documentType !== "pfe", index, dayObject, null, monthNames, isReadOnly, onChangeHourOrDay)
                            ))
                            }
                          </Grid.Column>
                        </Grid.Row>
                      </div>
                    </>
                  )
                }
                {
                  documentType === "t2" && (<>

                    {monthNames.map((monthName, monthIndex) => {
                      let value = 0;
                      if (budgetValues) {
                        const item = budgetValues.days.find(x => x.month === monthIndex + 1 && x.year === year);
                        if (!item) console.error("error with figure", `month: ${monthIndex + 1}`, `year: ${year}`);
                        value = Math.round(item.day * 100) / 100;
                      }

                      // Filtra i dati per il mese corrente

                      const dayObjectsForMonth = values.days.filter(h => h.month === monthIndex + 1 && h.year === year);
                      console.log('monthIndex', monthIndex);
                      // console.log('dayObjectsForMonth', dayObjectsForMonth);


                      return (
                        <Grid.Row style={noPadding}>
                          <Grid.Column key={monthIndex} style={{ paddingLeft: '0', paddingRight: '0', flex: '1 0 auto', minWidth: '0' }}>
                            <div style={budgetContainerStyle}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label>{monthName}: </label>
                                <span style={{ color: 'white', marginLeft: '5px' }}>{value} d</span>
                              </div>
                            </div>
                            <Grid.Row style={noPadding}>
                              <Grid.Column style={{ paddingLeft: '0' }}>
                                {dayObjectsForMonth
                                  .sort((a, b) => a.month - b.month)
                                  .map((dayObject) => {
                                    // console.log('dayObject', dayObject);
                                    // console.log('monthIndex', monthIndex);
                                    return renderListInput(
                                      documentType !== "pfe",
                                      monthIndex, // Usa l'indice originale
                                      dayObject,
                                      null,
                                      [monthNames][0],
                                      isReadOnly,
                                      onChangeHourOrDay
                                    );
                                  })}
                              </Grid.Column>
                            </Grid.Row>
                          </Grid.Column>
                        </Grid.Row>
                      );
                    })}
                  </>)
                }

                <div style={buttonContainerStyle}> {/* Container for the button */}
                  <SingleIconButton
                    icon="trash alternate"
                    color="red"
                    disabled={isDeleteDisabled()}
                    onClick={props.onDeleteProfessionalFigure}
                    type={'button'}
                    noMargin={true}
                  />
                </div>


              </Grid>
            </Grid.Column>


          </Grid.Row>
        </Grid>
      </Form >
    </Segment >
  )
}

export default ProfessionalFigure;