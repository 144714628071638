import { toast } from "react-semantic-toasts"
import { API } from "aws-amplify"
import constants from "../../../../utils/constants"

export const LOADING_APPROVAL_PROJECTS = "LOADING_APPROVAL_PROJECTS"
export const GET_APPROVAL_PROJECT_LIST = "GET_APPROVAL_PROJECT_LIST"
export const LOGGED_IN = "LOGGED_IN"
export const LOADING_GET_APPROVAL_HOURS = "LOADING_GET_APPROVAL_HOURS"
export const GET_APPROVAL_HOUR_LIST = "GET_APPROVAL_HOUR_LIST"
export const LOADING_APPROVE_HOURS = "LOADING_APPROVE_HOURS"
export const APPROVE_HOURS_SUCCESS = "APPROVE_HOURS_SUCCESS"
export const APPROVE_HOURS_ERROR = "APPROVE_HOURS_ERROR"
export const LOADING_GET_APPROVAL_EXPENSES = "LOADING_GET_APPROVAL_EXPENSES"
export const GET_APPROVAL_EXPENSE_LIST = "GET_APPROVAL_EXPENSE_LIST"
export const LOADING_APPROVE_EXPENSES = "LOADING_APPROVE_EXPENSES"
export const APPROVE_EXPENSES_SUCCESS = "APPROVE_EXPENSES_SUCCESS"
export const APPROVE_EXPENSES_ERROR = "APPROVE_EXPENSES_ERROR"
export const LOADING_EXPORT_DATA = "LOADING_EXPORT_DATA"
export const EXPORT_DATA_SUCCESS = "EXPORT_DATA_SUCCESS"
export const EXPORT_DATA_ERROR = "EXPORT_DATA_ERROR"
export const LOADING_APPROVAL_PROJECT = "LOADING_APPROVAL_PROJECT"
export const GET_APPROVAL_PROJECT = "GET_APPROVAL_PROJECT"

export const loadingApprovalProjects = () => {
  return { type: LOADING_APPROVAL_PROJECTS }
}

export const getApprovalProjectList = (projects) => {
  return { type: GET_APPROVAL_PROJECT_LIST, projects }
}

export const resetProjectSearch = () => {
  return getApprovalProjectList([])
}

export const startGetApprovalProjectList = (month, year, user, searchProjectValue) => {
  return (dispatch) => {
    dispatch(loadingApprovalProjects())
    const init = {}
    return API.get(
      "approval_projects",
      `/?month=${month}&year=${year}&user=${user}&searchProjectValue=${searchProjectValue}`,
      init
    )
      .then((projects) => {
        dispatch(getApprovalProjectList(projects))
      })
      .catch((error) => {
        dispatch(getApprovalProjectList([]))
        toast({
          title: "Error",
          description: "Couldn't retrieve projects list.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        })
      })
  }
}

export const loadingApprovalProject = () => {
  return { type: LOADING_APPROVAL_PROJECT }
}

export const getApprovalProject = (singleProject) => {
  return { type: GET_APPROVAL_PROJECT, singleProject }
}

export const resetProject = () => {
  return getApprovalProject({})
}

export const startGetApprovalProject = (month, year, user, projectId) => {
  return (dispatch) => {
    let body = {}
    dispatch(loadingApprovalProjects())
    API.get("approval_projects", `/${projectId}/?month=${month}&year=${year}`, body)
      .then((project) => {
        dispatch(getApprovalProject(project))
      })
      .catch((error) => {
        console.log("error", error)
        dispatch(resetProject())
        toast({
          title: "Error",
          description: "Couldn't retrieve project.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        })
      })
  }
}

export const loadingGetApprovalHours = () => {
  return { type: LOADING_GET_APPROVAL_HOURS }
}

export const getApprovalHourList = (hours) => {
  return { type: GET_APPROVAL_HOUR_LIST, hours }
}

export const startGetApprovalHourList = (month, year, user, projectId) => {
  return (dispatch) => {
    dispatch(loadingGetApprovalHours())
    const init = {}
    return API.get("approval_hours", `/?month=${month}&year=${year}&user=${user}&project_id=${projectId}`, init)
      .then((hours) => {
        dispatch(getApprovalHourList(hours))
      })
      .catch((error) => {
        dispatch(getApprovalHourList([]))
        toast({
          title: "Error",
          description: "Couldn't retrieve hour list.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        })
      })
  }
}

export const loadingApproveHours = () => {
  return { type: LOADING_APPROVE_HOURS }
}

export const approveHoursSuccess = () => {
  return { type: APPROVE_HOURS_SUCCESS }
}

export const approveHoursError = () => {
  return { type: APPROVE_HOURS_ERROR }
}

export const startApproveHours = (command, month, year, user, projectId, params) => {
  return (dispatch) => {
    dispatch(loadingApproveHours())

    let data = {}
    if (params) {
      data = {
        body: {
          hours: [...params],
        },
      }
    }

    let title, description_success, description_error
    if (command === "approve") {
      title = "Hours Approval"
      description_success = "Hours correctly approved"
      description_error = "Error approving hours of project with ID " + projectId
    } else if (command === "reject") {
      title = "Hours Reject"
      description_success = "Hours correctly rejected"
      description_error = "Error rejecting hours of project with ID " + projectId
    } else if (command === "reset") {
      title = "Hours Reset"
      description_success = "Hours correctly reset"
      description_error = "Error resetting hours of project with ID " + projectId
    } else {
      toast({
        title: "Hours Approval",
        description: "Invalid command",
        icon: "check",
        type: "error",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
      return
    }

    API.put("approve_hours", `/${projectId}/hours/`, data)
      .then((response) => {
        dispatch(approveHoursSuccess())
        toast({
          title: title,
          description: description_success,
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left",
        })
        if (command in ["approve", "reject"]) {
          toast({
            title: title,
            description: "Budget Actual will be automatically updated.",
            type: "info",
            icon: "check",
            time: constants.TOAST_WARNING_TIMEOUT,
            animation: "fly left",
          })
        }
        dispatch(startGetApprovalHourList(month, year, user, projectId))
      })
      .catch((error) => {
        description_error = error.response.data.error
        dispatch(approveHoursError())
        toast({
          title: title,
          description: description_error,
          icon: "x",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        })
      })
  }
}

export const loadingGetApprovalExpenses = () => {
  return { type: LOADING_GET_APPROVAL_EXPENSES }
}

export const getApprovalExpenseList = (expenses) => {
  return { type: GET_APPROVAL_EXPENSE_LIST, expenses }
}

export const startGetApprovalExpenseList = (month, year, user, projectId) => {
  return (dispatch) => {
    dispatch(loadingGetApprovalExpenses())
    const init = {}
    return API.get("approval_expenses", `/?month=${month}&year=${year}&user=${user}&project_id=${projectId}`, init)
      .then((expenses) => {
        dispatch(getApprovalExpenseList(expenses))
      })
      .catch((error) => {
        dispatch(getApprovalExpenseList([]))
        toast({
          title: "Error",
          description: "Couldn't retrieve expense list.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        })
      })
  }
}

export const loadingApproveExpenses = () => {
  return { type: LOADING_APPROVE_EXPENSES }
}

export const approveExpensesSuccess = () => {
  return { type: APPROVE_EXPENSES_SUCCESS }
}

export const approveExpensesError = () => {
  return { type: APPROVE_EXPENSES_ERROR }
}

export const startApproveExpenses = (command, month, year, user, projectId, params) => {
  return (dispatch) => {
    dispatch(loadingApproveExpenses())

    let data = {}
    if (params) {
      data = {
        body: {
          expenses: [...params],
        },
      }
    }

    let title, description_success, description_error
    if (command === "approve") {
      title = "Expenses Approval"
      description_success = "Expenses correctly approved"
      description_error = "Error approving expenses of project with ID " + projectId
    } else if (command === "reject") {
      title = "Expenses Reject"
      description_success = "Expenses correctly rejected"
      description_error = "Error rejecting expenses of project with ID " + projectId
    } else if (command === "reset") {
      title = "Expenses Reset"
      description_success = "Expenses correctly reset"
      description_error = "Error resetting expenses of project with ID " + projectId
    } else {
      toast({
        title: "Expenses Approval",
        description: "Invalid command",
        icon: "check",
        type: "error",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
      return
    }

    API.put("approve_expenses", `/${projectId}/expenses/`, data)
      .then((response) => {
        dispatch(approveExpensesSuccess())
        toast({
          title: title,
          description: description_success,
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left",
        })
        if (command in ["approve", "reject"]) {
          toast({
            title: title,
            description: "Budget Actual will be automatically updated.",
            type: "info",
            icon: "check",
            time: constants.TOAST_WARNING_TIMEOUT,
            animation: "fly left",
          })
        }
        dispatch(startGetApprovalExpenseList(month, year, user, projectId))
      })
      .catch((error) => {
        description_error = error.response.data.error
        dispatch(approveExpensesError())
        toast({
          title: title,
          description: description_error,
          icon: "x",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        })
      })
  }
}

export const loadingStartExportData = () => {
  return { type: LOADING_EXPORT_DATA }
}

export const exportDataSuccess = () => {
  return { type: EXPORT_DATA_SUCCESS }
}

export const exportDataError = () => {
  return { type: EXPORT_DATA_ERROR }
}

export const startExportData = (type, month, year, projectId, workingDays, ids_list) => {
  return (dispatch) => {
    dispatch(loadingStartExportData())

    let data = {
      body: {
        type: type,
        month: month,
        year: year,
        project: projectId,
        workingDays: workingDays,
        ids_list: ids_list,
      },
    }

    if (type !== "Hour" && type !== "Expense" && type !== "Summary") {
      toast({
        title: "Export",
        description: "Invalid command",
        icon: "check",
        type: "error",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
      return
    }

    API.post("export_data", `/`, data)

      .then((response) => {
        dispatch(exportDataSuccess())
        if (!response.url) {
          toast({
            title: "Export over email",
            description: "The report has been sent via email.",
            type: "success",
            icon: "check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: "fly left",
          })
        } else {
          window.open(response.url, "_blank")
        }
      })

      .catch((error) => {
        dispatch(exportDataError())
        toast({
          title: "Export Data",
          description: "Export data error",
          icon: "x",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        })
      })
  }
}
