import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Dimmer,
  Loader,
  Segment,
} from 'semantic-ui-react';
import IconButton from "../../../components/shared/buttons/IconButton";
import { areFiguresIncomplete, areThereDuplicateFigures, getBudgetDetails, getBudgetDetailsTotals, isBudgetT2ReadOnly } from '../../../utils/projectPlanningUtils';
import { capitalize } from '../../../utils/stringUtils';

import moment from "moment";
import { toast } from 'react-semantic-toasts';
import { history } from "../../../routers/AppRouters";
import { startGetCutoffPM } from '../../../store/actions/core/administration/cutoff';
import { startGetBudgetsById, startUpdateBudget } from '../../../store/actions/core/project-planning/budget';
import { clearbudget, setFinancialDataModalOpen, startSaveBudgetT2 } from '../../../store/actions/shared/professionalFigures';
import constants from '../../../utils/constants';
import BudgetT2NextEditProductContainer from './BudgetT2NextEditProductContainer';
import BudgetT2NextEditServiceContainer from './BudgetT2NextEditServiceContainer';


const FIELDS = {
  project_code: 'Project Code',
  currency_code: 'Currency',
  start_date: 'Start Date',
  end_date: 'End Date',
  status: 'Status',
  project_type: 'Project Type',
  offering_type: 'Offering Type',
};


function BudgetT2NextEditContainer(props) {
  const budget_id = props.match.params.budget_id

  useEffect(() => {
    props.getBudget(budget_id)
    props.getCutoffPM()

    return () => {
      props.clearBudget()
    }
  }, [])

  const onSave = (isConfirm) => {
    // console.log('onSave', );

    const onFailure = res => {
      var description = 'Error saving Budget';
      if (res.response !== undefined && res.response.status === 406) {
        description = res.response.data.error;
      }
      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      // console.log("onSuccess", res);

      toast({
        title: 'Success',
        description: 'The Budget has been saved',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      // history.push('/project-planning/budget_next')

      setTimeout(() => {
        history.push('/project-planning/budget_next');
      }, 1500); // Ritardo per evitare il reindirizzamento troppo veloce, il toast non viene visualizzato
    }

    let body = null;
    if (props.budget.type === "PROD") {
      body = {
        status: "confirm",
        budget_details: props.costsAndRevenues.map(x => ({
          ...x,
          total_revenues: null,
          billable_expenses: null,
          total_cost: null,
          expenses: null,
          delta_adj: null,
          delta_bill_exp: null,
        }))
      }

      const totalRevenues = props.costsAndRevenues.reduce((a, b) => a + b.revenues, 0)
      const totalCosts = props.costsAndRevenues.reduce((a, b) => a + b.cost, 0)
      if (props.actual_forecast_details.pfe_revenues !== totalRevenues) {
        toast({
          title: 'Warning',
          description: "Revenues cannot be different fom PFE revenues",
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
        return;
      }
      if (props.actual_forecast_details.pfe_costs !== totalCosts) {
        toast({
          title: 'Warning',
          description: "Costs cannot be different fom PFE costs",
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
        return;
      }

    } else {
      const budget_details = getBudgetDetails(props.actualForecastProfessionalFigures, props.actual_forecast_details.details, props.budget.project_type)
      const figures = props.actualForecastProfessionalFigures.map(figure => (
        figure.days.map(h => ({
          year: h.year,
          month: h.month,
          hours: h.day * 8,
          level: figure.level,
          area: figure.area,
          company_code: figure.company_code,
          internal: figure.external ? 0 : 1,
          sale_level: figure.sales_level,
          fromDB: undefined,
          cost_hour: figure.daily_cost / 8,
          currency_code: figure.currency_code,
          exchange_rate: figure.exchange_rate,
          site_percentage_expense: null,
          cost_hour_expense: null,
          //BUG FIX UAT 52: mandare daily_rate reale invece che 0, perche' 0 spacca lo storico del DB
          hourly_rate: figure.real_daily_rate / 8,
          real_daily_rate: undefined
        }))
      )).flat()
      body = {
        status: isConfirm ? 'confirm' : 'draft',
        budget_figures: figures,
        budget_details: budget_details
      }


      if (areFiguresIncomplete(props.actualForecastProfessionalFigures)) {
        return toast({
          title: 'Warning',
          description: 'There are incomplete professional figures, please delete or complete them.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }
      if (areThereDuplicateFigures(props.actualForecastProfessionalFigures, "budget")) {
        return toast({
          title: 'Warning',
          description: 'There are duplicate professional figures, please remove them.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }

      const totals = getBudgetDetailsTotals(budget_details)
      if ((totals.revenues + totals.deltaRevenues).toFixed(2) !== props.actual_forecast_details.pfe_revenues.toFixed(2)) {
        return toast({
          title: 'Warning',
          description: 'The revenues + delta revenues declared in the budget are different from the revenues of the PFE. Please review your revenue distribution.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }

      if ((totals.billableExpenses + totals.deltaBillableExpenses).toFixed(2) !== props.actual_forecast_details.pfe_billable_expenses.toFixed(2)) {
        return toast({
          title: 'Warning',
          description: 'The billable expenses + delta billable expenses in the budget are different from the billable expenses of the PFE. Please review your billable expenses distribution.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }

    }
    props.startSaveBudgetT2(budget_id, body, onFailure, onSuccess)
  }

  const handleUpdate = () => {
    const onFailure = (res) => {
      let description = ""
      if (res.response && res.response.status === 406) {
        description = res.response.data.error
      } else if (res.response && res.response.status === 500) {
        description = res.response.data.error // missing external rates
      }
      else {
        description = "The Budget hasn't been updated. Try again."
      }
      toast({
        title: "Error",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
    }

    const onSuccess = (res) => {
      //console.log("onSuccess", res);
      toast({
        title: "Success",
        description: "The Budget has been updated.",
        icon: "check",
        type: "success",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      })

      setTimeout(() => {
        window.location.reload();
      }, 1500); // Ritardo per evitare il reindirizzamento troppo veloce
    }

    var current_date = moment().format("YYYY-MM-DD")
    props.updateBudget(budget_id, current_date, onSuccess, onFailure)
  }

  //FIX bug 82 T0 in status "Confirm" o "Change Requested" non deve avere i tasti "Save" o "Confirm" cliccabili. 
  const isReadOnly = isBudgetT2ReadOnly(props.budget.status)

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 50px)' }}>
      <Dimmer active={props.loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>

      {props.budget !== undefined &&
        <>
          <Segment style={{ height: 'calc(100vh - 50px)', maxWidth: '200px', display: 'flex', flexDirection: 'column', margin: "0" }}>
            {Object.keys(FIELDS).map(key => (
              <div className="custom-form-field" key={key}>
                <label>{FIELDS[key]}</label>
                <div>{key === "status" ? capitalize(props.budget[key]) : props.budget[key]}</div>
              </div>
            ))}
            <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'column' }}>
              {props.budget.type === "TS" && <IconButton label='Costs and Revenues' icon='chart bar' color='green' onClick={() => props.setFinancialDataModalOpen(true)} />}
              {props.budget.type === "TS" && <IconButton label='Save' icon='hdd outline' color='blue' onClick={() => onSave(false)} disabled={areFiguresIncomplete(props.actualForecastProfessionalFigures) || isReadOnly} />}
              {props.budget.type === "PROD" && <IconButton label='Confirm Budget' icon='check circle outline' color='teal' onClick={() => onSave(true)} disabled={areFiguresIncomplete(props.actualForecastProfessionalFigures) || isReadOnly} />}
              {props.budget.type === "TS" && <IconButton label='Update Actual' icon='check circle outline' color='teal' onClick={handleUpdate} disabled={isReadOnly} />}
            </div>
          </Segment>

          {props.budget.type === "PROD" && <BudgetT2NextEditProductContainer budget_id={budget_id} />}
          {props.budget.type === "TS" && <BudgetT2NextEditServiceContainer budget_id={budget_id} />}
        </>
      }
    </div>
  )
}


const mapStateToProps = state => ({
  loading: state.professionalFiguresReducer.loading_budget ||
    state.cutoffReducer.loading_pm,

  budget: state.professionalFiguresReducer.budget.length === 0 ? {} : state.professionalFiguresReducer.budget.find(b => b.status_type === "t2"),

  actual_forecast_details: state.professionalFiguresReducer.actual_forecast_details,
  costsAndRevenues: state.professionalFiguresReducer.costsAndRevenues,
  actualForecastProfessionalFigures: state.professionalFiguresReducer.actualForecastProfessionalFigures,
});

const mapDispatchToProps = dispatch => {
  return {
    startSaveBudgetT2: (budgetId, body, onFailure, onSuccess) => dispatch(startSaveBudgetT2(budgetId, body, onFailure, onSuccess)),
    getBudget: (id) => dispatch(startGetBudgetsById(id)),
    getCutoffPM: () => dispatch(startGetCutoffPM()),
    setFinancialDataModalOpen: (isOpen) => dispatch(setFinancialDataModalOpen(isOpen)),
    updateBudget: (id, current_date, onSuccess, onFailure) => dispatch(startUpdateBudget(id, current_date, onSuccess, onFailure)),
    clearBudget: () => dispatch(clearbudget())
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetT2NextEditContainer);
