import { API, Auth } from "aws-amplify";
import ability from "../../../abilities/ability";

export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const LOGGING = "LOGGING";
export const NOT_AUTHORIZED = "NOT_AUTHORIZED";
export const AUTH = "AUTH";
export const CHANGE_CN = "CHANGE_CN";
export const UPDATE_ABILITIES = "UPDATE_ABILITIES";

export const changeCn = (cn_id) => {
  return { type: CHANGE_CN, cn_id };
};

export function loggedIn(user = {}, cn, user_id, unique_id, name, company_code, user_details, user_groups, company_details) {
  return { type: LOGGED_IN, user, cn, user_id, unique_id, name, company_code, user_details, user_groups, company_details};
}

export function loggedOut() {
  return { type: LOGGED_OUT };
}

export function logging() {
  return { type: LOGGING };
}

export function not_authorized(user = {}, message) {
  return { type: NOT_AUTHORIZED, user, message };
}

export function update_abilities(abilities = []) {
  return { type: UPDATE_ABILITIES, abilities};
}

/**
 * This State usage is mainly spinner preview
 */
export function requestLogin() {
  return { type: REQUEST_LOGIN };
}

/**
 * Login Action Call
 */

export function startLogin(user) {
  return (dispatch) => {
    //console.log("Checking use CN");
    dispatch(startCheckCN(user));
  };
}

export function startLogout() {
  return (dispatch) => {
    Auth.signOut()
      .then(data => {
        localStorage.setItem("rules", "[]");
        dispatch(loggedOut());
      })
      .catch(err => {
        //console.log(err);
      });
  };
}

export function startLogging() {
  return (dispatch) => {
    dispatch(logging());
  };
}

export function startCheckCN(user) {
  return (dispatch) => {
    Auth.currentSession().then(res => {
      const payload = res.getIdToken().decodePayload();

      if(!payload["user_details"] || JSON.parse(payload['user_details']).length === 0){
        dispatch(not_authorized(user, "You have no assigned user details."));
      } else {


        let user_details = JSON.parse(payload["user_details"]);
        let cn_id = user_details[0].cn_id;
        let user_id = user_details[0].user_id;
        let unique_id = user_details[0].unique_id;
        let company_code = user_details[0].company_code;
        let user_groups = JSON.parse(payload["user_groups"]);
        let company_details = JSON.parse(payload["company_details"]);
        let name = payload["name"];

        let rules = localStorage.getItem("rules");
        if (rules && JSON.parse(rules).length > 0) {
          ability.update(JSON.parse(rules));
        } else {
          //console.log( "ABILITY: ",ability.rules);
          API.get("permissions", "/", {})
            .then(response => {
              const response_body = JSON.parse(response);
              const rules = response_body['user_permission']
              if (rules.find((r) => r.actions.split("_")[0] === "administration" || r.subject === "projects-admin")) {
                rules.push({ subject: "admin", actions: "admin:Read" });
              }
              //commentare la riga successiva per i permessi reali
              //localStorage.setItem("rules", JSON.stringify(rules));
              ability.update(rules);
              //console.log( "ABILITY: ",ability.rules);
              dispatch(update_abilities(ability.rules));
            })
            .catch(error => console.log(error));
        }

        if (cn_id !== null && cn_id !== "") {
          dispatch(loggedIn(user, cn_id, user_id, unique_id, name, company_code, user_details, user_groups, company_details));
        } else {
          //console.log("[AUTH] NO CN_ID");
          dispatch(not_authorized(user, "There is no CN ID assigned to you!"));
        }
      }
    });
  };
}


/* TODO is this needed???
export function sendMailNotAuthorized() {
  return (dispatch, getState) => {
    AUTH.currentUser();
  };
}*/
