
import _ from 'lodash';
import React, { useState } from 'react';
import {
  Form,
  Modal,
  Tab,
  Table,
  TabPane
} from 'semantic-ui-react';
import { monthStrAbbr } from '../../../utils/formUtils';
import { getBudgetDetails, getBudgetDetailsTotals, getDatesAndYears, limitDigits } from '../../../utils/projectPlanningUtils';

const getInputStyle = ({ isCutoffDisabled, isCompanyCutoffDisabled, isDisabled, isUpdateActualDisabled }, isBudget = false, isAffectedByCompanyCutoff = false) => {
  if (isBudget) return { padding: 0, background: '' }
  return { padding: 0, background: isCutoffDisabled || (isAffectedByCompanyCutoff && isCompanyCutoffDisabled) || (isUpdateActualDisabled && isAffectedByCompanyCutoff) ? "#989FCE" : isDisabled ? 'rgba(40,40,40,.3)' : '' }
}

const getTableCellStyle = ({ isCutoffDisabled, isCompanyCutoffDisabled, isDisabled, isUpdateActualDisabled }, isBudget = false, isAffectedByCompanyCutoff = false) => {
  if (isBudget) return { background: '' }
  return { background: isCutoffDisabled || (isAffectedByCompanyCutoff && isCompanyCutoffDisabled) || (isUpdateActualDisabled && isAffectedByCompanyCutoff) ? "#989FCE" : isDisabled ? 'rgba(40,40,40,.3)' : '' }
}

const DetailsPane = ({ figures, storeDetails, project_type, year, isReadOnly, setValues, theOneRing, isBudget }) => {
  const details = getBudgetDetails(figures, storeDetails, project_type)
  const totals = getBudgetDetailsTotals(details)
  // console.log('project_type', project_type);
  console.log('details', details);

  return (
    <TabPane>
      <Table celled definition>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Total</Table.HeaderCell>
            {monthStrAbbr.map((monthName, monthIndex) => (<Table.HeaderCell key={monthIndex}>{monthName}</Table.HeaderCell>))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Days</Table.Cell>
            <Table.Cell>{totals.days.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                {item.days.toFixed(2)}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Total Revenues</Table.Cell>
            <Table.Cell>{(totals.totalRevenues || 0).toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                {(item.total_revenues || 0).toFixed(2)}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Revenues</Table.Cell>
            <Table.Cell>{totals.revenues.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                {project_type === "Time Material" ?
                  item.revenues.toFixed(2) :
                  <input
                    style={getInputStyle(theOneRing(item.year, item.month), isBudget)}
                    disabled={theOneRing(item.year, item.month).isDisabled || isReadOnly}
                    type="number"
                    value={item.revenues}
                    onChange={(event) => {
                      event.persist();
                      if (event.target !== null && event.target !== undefined) {
                        setValues(item.year, item.month, {
                          revenues: limitDigits(event.target.value)
                        })
                      }
                    }}
                  />
                }
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Billable Expenses</Table.Cell>
            <Table.Cell>{totals.billableExpenses.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget, true)} key={index}>
                <input
                  style={getInputStyle(theOneRing(item.year, item.month), isBudget, true)}
                  disabled={
                    theOneRing(item.year, item.month).isDisabled
                    || theOneRing(item.year, item.month).isCompanyCutoffDisabled
                    || theOneRing(item.year, item.month).isUpdateActualDisabled
                    || isReadOnly}
                  type="number"
                  value={item.billable_expenses}
                  onChange={(event) => {
                    event.persist();
                    if (event.target !== null && event.target !== undefined) {
                      setValues(item.year, item.month, {
                        billable_expenses: limitDigits(event.target.value)
                      })
                    }
                  }}
                />
              </Table.Cell>
            ))}
          </Table.Row>
          {/* FIX 70 Campo total cost mancante: il campo che fa la somma di cost + expenses non è presente nella sezione Costs & Revenues come da analisi. */}
          <Table.Row>
            <Table.Cell>Total Costs</Table.Cell>
            <Table.Cell>{totals.totalCosts.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                {item.total_cost.toFixed(2)}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Costs</Table.Cell>
            <Table.Cell>{totals.costs.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                {item.cost.toFixed(2)}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Expenses</Table.Cell>
            <Table.Cell>{totals.expenses.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget, true)} key={index}>
                <input
                  style={getInputStyle(theOneRing(item.year, item.month), isBudget, true)}
                  disabled={
                    theOneRing(item.year, item.month).isDisabled
                    || theOneRing(item.year, item.month).isCompanyCutoffDisabled
                    || theOneRing(item.year, item.month).isUpdateActualDisabled
                    || isReadOnly}
                  type="number"
                  value={item.expenses}
                  onChange={(event) => {
                    event.persist();
                    if (event.target !== null && event.target !== undefined) {
                      setValues(item.year, item.month, {
                        expenses: limitDigits(event.target.value)
                      })
                    }
                  }}
                />
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Delta Revenues</Table.Cell>
            <Table.Cell>{totals.deltaRevenues.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                <input
                  style={getInputStyle(theOneRing(item.year, item.month), isBudget)}
                  disabled={theOneRing(item.year, item.month).isDisabled || isReadOnly}
                  type="number"
                  value={item.delta_adj}
                  onChange={(event) => {
                    event.persist();
                    if (event.target !== null && event.target !== undefined) {
                      setValues(item.year, item.month, {
                        delta_adj: limitDigits(event.target.value)
                      })
                    }
                  }}
                />
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Delta Billable Expenses</Table.Cell>
            <Table.Cell>{(totals.deltaBillableExpenses || 0).toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                <input
                  style={getInputStyle(theOneRing(item.year, item.month), isBudget)}
                  disabled={theOneRing(item.year, item.month).isDisabled || isReadOnly}
                  type="number"
                  value={item.delta_bill_exp}
                  onChange={(event) => {
                    event.persist();
                    if (event.target !== null && event.target !== undefined) {
                      setValues(item.year, item.month, {
                        delta_bill_exp: limitDigits(event.target.value)
                      })
                    }
                  }}
                />
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Margin</Table.Cell>
            <Table.Cell>{(totals.margin || 0).toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                {(item.margin || 0).toFixed(2)}
              </Table.Cell>
            ))}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Margin %</Table.Cell>
            <Table.Cell>{(totals.marginPercentage || 0).toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                {(item.margin_percentage || 0).toFixed(2)}
              </Table.Cell>
            ))}
          </Table.Row>
        </Table.Body>
      </Table>
    </TabPane>
  )
}

function NextCostsAndRevenuesT2Modal(props) {
  const { isOpen, onClose, budget, setValues, actual_forecast_figures, budget_figures, actual_forecast_details, budget_details, isReadOnly, theOneRing } = props

  if (actual_forecast_details === undefined || actual_forecast_details.details === undefined || budget_details === undefined || budget_details.details === undefined) {
    return null;
  }

  const { startDate, endDate, numberOfYears, years } = getDatesAndYears(budget)

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeInnerPane, setActiveInnerPane] = useState(0);

  const menuItemClassActual = activeInnerPane === 0 ? 'menu-item-active' : 'menu-item'
  const menuItemClassBudget = activeInnerPane !== 0 ? 'menu-item-active' : 'menu-item'

  const onChangeInnerTab = (_event, data) => {
    setActiveInnerPane(data.activeIndex)
    // console.log('onChangeInnerTab', data.activeIndex);
  }

  const onOuterTabChange = (_event, data) => {
    setActiveIndex(data.activeIndex)
    // console.log('onOuterTabChange', data.activeIndex);
  }



  return (
    <Modal
      size="large"
      open={isOpen}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={onClose}
      closeIcon
      dimmer="blurring"
    >
      <Modal.Header>Costs & Revenues</Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem', float: 'right' }}>
            <span>PFE Revenues <b>{activeInnerPane === 0 ? actual_forecast_details.pfe_revenues : budget_details.pfe_revenues}</b></span>
            <span>PFE Bill Exp <b>{activeInnerPane === 0 ? actual_forecast_details.pfe_billable_expenses : budget_details.pfe_billable_expenses}</b></span>
          </div>
          <Tab onTabChange={onOuterTabChange}
            panes={
              years.map((year, index) => ({
                menuItem: {
                  content: year.toString(),
                  className: activeIndex === index ? 'menu-item-active' : 'menu-item'
                },
                render: () => (
                  <TabPane>
                    {/* bugfix UAT 53: togliere la modale "budget" per i time material nella cost&revenues */}
                    {props.budget.project_type === "Time Material" ?
                      <DetailsPane
                        figures={actual_forecast_figures}
                        storeDetails={actual_forecast_details.details}
                        project_type={props.project_type}
                        year={year}
                        startDate={startDate}
                        endDate={endDate}
                        isReadOnly={isReadOnly}
                        setValues={setValues}
                        theOneRing={theOneRing}
                      />
                      : <Tab
                        onTabChange={onChangeInnerTab}
                        panes={[
                          {
                            menuItem: {
                              content: "Actual & Forecast",
                              className: menuItemClassActual
                            },
                            render: () => (
                              <DetailsPane
                                figures={actual_forecast_figures}
                                storeDetails={actual_forecast_details.details}
                                project_type={props.project_type}
                                year={year}
                                startDate={startDate}
                                endDate={endDate}
                                isReadOnly={isReadOnly}
                                setValues={setValues}
                                theOneRing={theOneRing}
                              />
                            )
                          },
                          {
                            menuItem: {
                              content: "Budget",
                              className: menuItemClassBudget
                            },
                            render: () => (
                              <DetailsPane
                                figures={budget_figures}
                                storeDetails={budget_details.details}
                                project_type={props.project_type}
                                year={year}
                                startDate={startDate}
                                endDate={endDate}
                                theOneRing={theOneRing}
                                isReadOnly={true}
                                setValues={() => { }}
                                isBudget={true}
                              />
                            )
                          }
                        ]}
                      />
                    }
                  </TabPane>
                )
              }))
            }
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default NextCostsAndRevenuesT2Modal;
